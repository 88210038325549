import { Fragment } from "react"

import { Tooltip } from "@material-ui/core"

import { BatteryAndChargeStatusIcon } from "src/components/Homes/DeviceDetails/BatteryAndChargeStatusIcon"
import { OnlineStatus } from "src/components/Homes/DeviceDetails/OnlineStatus"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  IdWrapper,
  PillsWrapper,
  StatusWrapper,
} from "src/components/Paradise/sharedStyles"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { IParadiseDevice } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { colors } from "src/ui/colors"
import InfoIcon from "src/ui/icons/info.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { WifiIcon } from "src/ui/WifiIcon/WifiIcon"
import { formatDate } from "src/utils/l10n"

export function ParadiseDevicesTable({
  devices,
  offset,
  limit,
  setOffset,
  totalCount,
  error,
}: {
  devices: IParadiseDevice[]
  offset: number
  limit: number
  setOffset: (offset: number) => void
  totalCount?: number
  error?: {
    hasError: boolean
    title: React.ReactNode
    description?: React.ReactNode
  }
}) {
  const { navigate } = useRouter()

  const header = [
    <div key={"device-id"}>Device id</div>,
    <div key={"description"}>Description</div>,
    <div key={"last-updated-at"}>Last updated at</div>,
    <div key={"tags"}>Tags</div>,
    <div key={"hardware"}>Hardware</div>,
    <div key={"status"}>Status</div>,
    <div key={"signal"}>Signal</div>,
    <div key={"battery"}>Battery</div>,
  ]

  function getHardwareText(hardwareVersion: number) {
    const hardware = getDeviceHardwareType(hardwareVersion)

    if (hardware.revision) {
      return `${hardware.type} (${hardware.revision})`
    }

    return hardware.type
  }

  const rows = devices.map((device) => (
    <Fragment key={device.device_id}>
      <div>
        <IdWrapper>
          <InternalLink
            to={Routes.ParadiseDevice.location(device.device_id)}
            onClick={(e) => e.stopPropagation()}
          >
            {device.device_id}
          </InternalLink>
          {!device.active && (
            <Tooltip title="Inactive">
              <InfoIcon width={12} fill={colors.systemEmergencyForeground} />
            </Tooltip>
          )}
        </IdWrapper>
      </div>
      <div>{device.description}</div>
      <div>
        {device.last_heard_from_at
          ? formatDate({ date: device.last_heard_from_at, timezone: "utc" })
          : "-"}
      </div>
      {device.tags.length > 0 ? (
        <div>
          <PillsWrapper $noWrap>
            {device.tags.map((tag) => (
              <MBadge key={tag} size="small" color="neutral">
                {tag}
              </MBadge>
            ))}
          </PillsWrapper>
        </div>
      ) : (
        <div>-</div>
      )}
      <div>
        <MText>{getHardwareText(device.hardware_version)}</MText>
      </div>
      <div>
        <OnlineStatus online={!device.offline} />
      </div>
      <div>
        <StatusWrapper>
          <WifiIcon
            quality={device.wlan?.signal_quality}
            offline={device.offline}
            width={20}
            height={20}
          />
          {device.wlan?.rssi && (
            <MText variant="nano">{device.wlan?.rssi}</MText>
          )}
        </StatusWrapper>
      </div>
      <div>
        <StatusWrapper>
          <BatteryAndChargeStatusIcon
            percent={device.battery?.percent}
            offline={device.offline}
            chargeStatus={device.charge_status}
            width={20}
            height={20}
          />
          {device.battery?.voltage && (
            <MText variant="nano">{device.battery?.voltage.toFixed(2)}V</MText>
          )}
        </StatusWrapper>
      </div>
    </Fragment>
  ))

  return (
    <>
      <ParadiseTable
        header={header}
        rows={rows ?? []}
        templateColumns={`repeat(${header.length}, auto)`}
        onRowClick={(index) => {
          navigate(Routes.ParadiseDevice.location(devices[index].device_id))
        }}
        error={error}
      />
      <ParadisePager
        totalCount={totalCount}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
      />
    </>
  )
}
